import React from "react";
import GatedVideoCard from "../../components/GatedVideoCard/GatedVideoCard";
import { GatedVideoNode } from "@types";
import styles from "./GatedVideoListContainer.module.scss";

type GatedVideoListContainerProps = {
  videosList: GatedVideoNode[];
  title: string;
  locked?: boolean;
};

const GatedVideoListContainer: React.FC<GatedVideoListContainerProps> = ({
  videosList,
  title,
  locked,
}) => {
  return (
    <section className={`pad-top--large full-width`}>
      <h2 className={styles.videosListTitle}>{title}</h2>

      <div className={styles.videosContainer}>
        {videosList.map((video, index) => (
          <GatedVideoCard key={index} video={video} signupSuccess={!locked} />
        ))}
      </div>
    </section>
  );
};

export default GatedVideoListContainer;
