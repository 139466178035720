import React, { useEffect } from "react";
import { PageProps, graphql } from "gatsby";
import { GatedVideosPgType } from "@types";
import MainLayout from "../../layouts/main";
import HeroGatedVideoContainer from "../../containers/HeroGatedVideoContainer/HeroGatedVideoContainer";
import GatedVideoListContainer from "../../containers/GatedVideoListContainer/GatedVideoListContainer";
import { YoastSEOType } from "../../assets/Types";
import SEO from "gatsby-plugin-wpgraphql-seo";
import { getLocalStorageItem } from "../../utils/LocalStorage";
import useGatedVideoStore from "../../sp-state/useGatedVideoStore";
import { deIndexSeo } from "../../utils/deIndexSeo";

type DataProps = {
  wpPage: {
    seo: YoastSEOType;
    template: {
      gatedVideosPg: GatedVideosPgType;
    };
  };
};

const GatedVideoLandingPage: React.FC<PageProps<DataProps>> = ({ data }) => {
  const { wpPage } = data;
  const {
    template: { gatedVideosPg },
  } = wpPage;

  const { locked, setLocked } = useGatedVideoStore(state => state);

  useEffect(() => {
    if (!getLocalStorageItem("gated_pass")) {
      setLocked(true);
    }
  }, []);

  const heroData = {
    gatedVideosHeroBadge: gatedVideosPg?.gatedVideosHeroBadge,
    gatedVideosHeroTitle: gatedVideosPg?.gatedVideosHeroTitle,
    gatedVideosHeroSubcopy: gatedVideosPg?.gatedVideosHeroSubcopy,
    gatedVideosHeroFirstButton: gatedVideosPg?.gatedVideosHeroFirstButton,
    gatedVideosHeroSecondButton: gatedVideosPg?.gatedVideosHeroSecondButton,
    gatedVideosHeroWistiaId: gatedVideosPg?.gatedVideosHeroWistiaId,
    videoData: {
      videoId: gatedVideosPg.gatedVideosHeroWistiaId,
      videoBackgroundImage: {
        image: gatedVideosPg.gatedVideosHeroImage.gatsbyImage,
        altText: gatedVideosPg.gatedVideosHeroImage.altText,
      },
    },
  };

  return (
    <MainLayout>
      {wpPage.seo && (
        <SEO post={deIndexSeo(wpPage as unknown as Queries.WpPage)} />
      )}
      <HeroGatedVideoContainer heroData={heroData} locked={locked} />
      <GatedVideoListContainer
        title={gatedVideosPg?.gatedVideosTitle}
        videosList={gatedVideosPg?.gatedVideosList}
        locked={locked}
      />
    </MainLayout>
  );
};

export const GATED_VIDEO_LP_PAGE_QUERY = graphql`
  query GatedVideoLPTemplateQuery($id: String!) {
    wpPage(id: { eq: $id }) {
      ...YoastSEOFragment
      template {
        ... on WpGatedVideoLPTemplate {
          gatedVideosPg {
            gatedVideosHeroBadge
            gatedVideosHeroFirstButton
            gatedVideosHeroImageAltText
            gatedVideosHeroSecondButton
            gatedVideosHeroSubcopy
            gatedVideosHeroTitle
            gatedVideosHeroWistiaId
            gatedVideosTitle
            gatedVideosHeroImage {
              altText
              gatsbyImage(layout: FULL_WIDTH, width: 1200)
            }
            gatedVideosList {
              toggleLock
              videoLength
              videoTitle
              videoWistiaId
              videoBackground {
                altText
                gatsbyImage(layout: FULL_WIDTH, width: 1200)
              }
            }
          }
        }
      }
    }
  }
`;

export default GatedVideoLandingPage;
